import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Reviews.css";
import star from "../../images/star.svg";
import { useLocation } from "react-router-dom";
import one from "../../images/rev1.webp";
import two from "../../images/rev2.webp";
import three from "../../images/rev3.webp";
import four from "../../images/rev4.webp";
import five from "../../images/rev5.webp";

const Reviews = () => {
  const location = useLocation();
  return (
    <section className="trof-reviews pad">
      {location.pathname === "/reviews" ? (
        <h1 className="trof-reviews__header">Yorumlar</h1>
      ) : (
        <h2 className="trof-reviews__header">Yorumlar</h2>
      )}
      <Swiper
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={true}
        spaceBetween={10}
        breakpoints={{
          749: {
            slidesPerView: 2,
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        className="trof-review__swiper"
      >
        <SwiperSlide className="trof-review__slide trof-reviews__item">
          <img
            src={one}
            title="reviewer one"
            alt="reviewer one"
            width="100"
            height="100"
            className="trof-reviews__img"
          />
          <p className="trof-review__name t-24">Ayşe Hanım:</p>
          <p className="trof-review__text t-20">
            "Best-Troffers sayesinde hayalimizdeki tatili yaşadık. Her detay
            düşünülmüştü, teşekkürler!"
          </p>
        </SwiperSlide>
        <SwiperSlide className="trof-review__slide trof-reviews__item">
          <img
            src={two}
            title="reviewer two"
            alt="reviewer two"
            width="100"
            height="100"
            className="trof-reviews__img"
          />
          <p className="trof-review__name t-24">Mehmet Bey:</p>
          <p className="trof-review__text t-20">
            "Otel seçimleri harikaydı, her biri beklentilerimizin ötesindeydi."
          </p>
        </SwiperSlide>
        <SwiperSlide className="trof-review__slide trof-reviews__item">
          <img
            src={three}
            title="reviewer three"
            alt="reviewer three"
            width="100"
            height="100"
            className="trof-reviews__img"
          />
          <p className="trof-review__name t-24">Elif K:</p>
          <p className="trof-review__text t-20">
            "Turlar mükemmeldi, rehberimiz çok bilgiliydi. Harika bir
            deneyimdi."
          </p>
        </SwiperSlide>
        <SwiperSlide className="trof-review__slide trof-reviews__item">
          <img
            src={four}
            title="reviewer four"
            alt="reviewer four"
            width="100"
            height="100"
            className="trof-reviews__img"
          />
          <p className="trof-review__name t-24">Ali V.:</p>
          <p className="trof-review__text t-20">
            "Güvenilir ve profesyonel bir hizmet aldık. Kesinlikle tekrar tercih
            edeceğim."
          </p>
        </SwiperSlide>
        <SwiperSlide className="trof-review__slide trof-reviews__item">
          <img
            src={five}
            title="reviewer five"
            alt="reviewer five"
            width="100"
            height="100"
            className="trof-reviews__img"
          />
          <p className="trof-review__name t-24">Zeynep Y.:</p>
          <p className="trof-review__text t-20">
            "Her şey çok iyi organize edilmişti. Tatilimiz boyunca hiçbir sorun
            yaşamadık."
          </p>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Reviews;
