import React from "react";
import "./Footer.css";
import pin from "../../images/pin.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="contacts" className="footer">
      <div className="footer__el1">
        <p className="footer__cookie-header">Daha fazla bilgi:</p>
        <p className="t-20">
          <span className="footer__span"> best-troffers.com</span> - en iyi
          deneyimi yaşamanızı sağlamak için çerezleri kullanır. Sitede kalmanız
          halinde çerezlerinizi kullanmamızı kabul etmiş olursunuz.{" "}
          <Link to="cookies" className="t-20">
            Daha fazlasını öğrenin
          </Link>
        </p>
      </div>
      <div className="footer__el2">
        <p className="t-20 footer__adress">
          <img src={pin} title="pin" alt="pin" />
          Cumhuriyet Caddesi No: 50, Beyoğlu, Istanbul, Turkey
        </p>
        <Link to="privacy" className="t-20">
          Gizlilik Politikası
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
