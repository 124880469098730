import React from "react";
import "./DirectionPages.css";

import hotOne from "../../images/Avrasya-Hostel.webp";
import hotTwo from "../../images/Aqua-Marin-Beach-Resort.webp";
import hotThree from "../../images/Reyhanli-Otelleri-Tugra-Otel.webp";

import pin from "../../images/pin.svg";
import time from "../../images/time.svg";
import events from "../../images/events.svg";

const Turkey = () => {
  return (
    <main className="trof-direction-page">
      <section className="trof-cover trof-direction-page-cover page-one">
        <div className="trof-cover__overlay">
          <h1 className="trof-cover__header">Türkiye</h1>
        </div>
      </section>

      <section className="trof-hotels trof-direction__pages pad">
        <h2 className="trof-hotels__header text-center">
          En İyi Otel Tavsiyelerimiz
        </h2>
        <p className="t-24  hotels__text">
          Türkiye, muhteşem tarihi kalıntıları, güzel plajları ve eşsiz kültürel
          zenginlikleri ile ünlü bir ülkedir. Seyahat acentemiz, gerçek Türk
          misafirperverliğinin ve konforunun tadını çıkarabilmeniz için en iyi
          otelleri seçti. İstanbul'un tarihi ve modern otellerinden, Ege ve
          Akdeniz kıyılarındaki huzurlu tatil köylerine kadar, unutulmaz bir
          tatil için ideal yerler sunuyoruz.
        </p>
        <ul className="trof-hotels__container">
          <li className="trof-hotel hotel-one">
            <div className="trof-hotel__img-container">
              <img
                src={hotOne}
                alt="Avrasya Hostel"
                title="Avrasya Hostel"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">Avrasya Hostel</p>
              <p className="trof-hotel__about t-20">
                Avrasya Hostel, şehrin kalbinde rahat ve bütçe dostu bir
                konaklama imkanı sunmaktadır. Konuksever atmosferiyle tanınan bu
                hostel, yerel kültürü uygun bir bütçeyle keşfetmek isteyen
                gezginler için idealdir. Konfor düşünülerek tasarlanmış odalarda
                keyifli bir konaklama sağlamak için temel olanaklar mevcuttur.
                Konuklar, uzun süreli konaklamalar veya kendi yemeklerini
                pişirmeyi tercih edenler için uygun hale getiren ortak mutfağın
                keyfini çıkarabilirler. Güler yüzlü personel, şehir deneyiminizi
                zenginleştirecek seyahat ipuçları ve tavsiyeler konusunda her
                zaman yardımcı olmaya hazırdır.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Cankurtaran Mahallesi Seyit Hasan Sokak No:12 Sultanahmet,
                İstanbul 34110 Türkiye
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Şehir turları ve günlük geziler
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g293974-d6429248-Reviews-Avrasya_Hostel-Istanbul.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-two">
            <div className="trof-hotel__img-container">
              <img
                src={hotTwo}
                alt="Aqua Marin Beach Resort"
                title="Aqua Marin Beach Resort"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Aqua Marin Beach Resort
              </p>
              <p className="trof-hotel__about t-20">
                Aqua Marin Beach Resort, deniz kenarında dinlenmek isteyenler
                için mükemmel bir kaçış noktasıdır. El değmemiş bir plajda yer
                alan tesis, nefes kesici okyanus manzaraları ve lüks konaklama
                birimleri sunmaktadır. Konuklar çeşitli su sporlarıyla
                kendilerini şımartabilir veya spada gevşeyebilirler. Tesiste
                ayrıca sahil kenarında rahat atıştırmalıklardan şık kaliteli
                yemek deneyimlerine kadar çok sayıda yemek seçeneği
                bulunmaktadır. Aileler çocuk kulübünü ve gün boyunca sunulan
                eğlence seçeneklerini takdir edecektir.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Cengerkoy Mevkii, Kizilot, Manavgat Türkiye
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Havuz aktiviteleri ve sahil sporları
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g3961436-d3906688-Reviews-Aqua_Marin_Beach_Resort-Kizilot_Manavgat_Turkish_Mediterranean_Coast.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-three">
            <div className="trof-hotel__img-container">
              <img
                src={hotThree}
                alt="Reyhanli Otelleri, Tugra Otel, Hasan Demir"
                title="Reyhanli Otelleri, Tugra Otel, Hasan Demir"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Reyhanli Otelleri, Tugra Otel, Hasan Demir
              </p>
              <p className="trof-hotel__about t-20">
                Reyhanlı Otelleri, konforu geleneksel Türk misafirperverliği ile
                birleştiren büyüleyici bir oteldir. Konuklar, yerel kültürü ve
                tarihi yansıtan güzel bir şekilde dekore edilmiş odalarla
                karşılaşabilirler. Otel, yerel cazibe merkezlerine ve pazarlara
                kolay erişim sağlayarak ziyaretçilerin bölgenin canlı
                atmosferine kendilerini kaptırmalarına olanak tanır. Tesis
                bünyesindeki restoranda leziz yöresel yemekler servis edilmekte
                olup konaklamanız boyunca keyifli bir mutfak deneyimi yaşamanızı
                sağlar.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Cilvegozu Yolu Sokak No:2 Yeni Sehir Mahallesi, Reyhanli 31500
                Türkiye
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Yerel kültür etkinlikleri ve günlük turlar
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g11997879-d21287729-Reviews-Reyhanli_Otelleri_Tugra_Otel_Hasan_Demir-Reyhanli_Hatay_Province_Turkish_Mediterran.html"
              >
                rezerv
              </a>
            </div>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default Turkey;
