import React from "react";
import "./Main.css";
import BestHotels from "../HotDeals/HotDeals";
import Reviews from "../Reviews/Reviews";
import turkey from "../../images/turkey-link.webp";
import denmark from "../../images/denmark-link.webp";
import holland from "../../images/holland-link.webp";
import { Link } from "react-router-dom";
// import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="main">
      {/* <MainPopup/> */}
      <section className="trof-cover">
        <div className="trof-cover__overlay">
          <h1 className="trof-cover__header">
            Güvenli, daha iyi ve unutulmaz tatilleriniz
          </h1>
          <p className="rof-cover__text t-24">
            Best-Troffers.com, yılların getirdiği deneyim ve profesyonellik ile
            her tatilde unutulmaz anılar biriktirmenizi sağlar. Özel tatil
            paketlerimiz ve müşteri memnuniyeti odaklı hizmet anlayışımızla,
            sizi dünyadaki en iyi otellerde ağırlamaktan gurur duyuyoruz.
            Tatilinizi mükemmel kılmak için her detayı düşünerek, size özel
            çözümler sunuyoruz.
          </p>
        </div>
      </section>

      <section className="trof-directions pad">
        <div className="trof-directions__text">
          <h2 className="trof-directions__header">yol tarifi seç</h2>
          <p className="trof-directions__paragraph t-24">
            Avrupa’ya unutulmaz bir seyahat mi planlıyorsunuz? Seçiminizde size
            yardımcı olmaya hazırız! Avrupa, her biri kendine has özelliklere
            sahip ve tarihi, kültürü ile hayranlık uyandıran birçok farklı
            destinasyon sunuyor. En iyi oteller, cazibe merkezleri ve bu harika
            şehirlerdeki gezilecek yerler hakkında bilgi edinin.
          </p>
        </div>

        <div className="trof-directions__link-container">
          <Link to="/turkey" className="trof-directions__link">
            <img
              src={turkey}
              alt="link to page about turkey"
              title="link to page about turkey"
              width="333"
              height="240"
              className="trof-directions__img"
            />
            <div className="trof-directions__overlay">
              <p className="t-24 col-yellow trof-directions__overlay-text">Türkiye'deki Oteller</p>
            </div>
          </Link>

          <Link to="/denmark" className="trof-directions__link">
            <img
              src={denmark}
              alt="link to page about denmark"
              title="link to page about denmark"
              width="333"
              height="240"
              className="trof-directions__img"
            />
            <div className="trof-directions__overlay">
              <p className="t-24 col-yellow trof-directions__overlay-text">Danimarka'daki Oteller</p>
            </div>
          </Link>
          <Link to="/holland" className="trof-directions__link grid-two">
            <img
              src={holland}
              alt="link to page about holland"
              title="link to page about holland"
              width="333"
              height="500"
              className="trof-directions__img"
            />
            <div className="trof-directions__overlay">
              <p className="t-24 col-yellow trof-directions__overlay-text">Hollanda'daki Oteller</p>
            </div>
          </Link>
        </div>
      </section>

      <BestHotels />
      <Reviews />
    </main>
  );
};

export default MainCover;
