import React from "react";

const Privacy = () => {
  return (
    <main>
      <section class="trof-privacy pad">
        <h1>best-troffers.com için Gizlilik Politikası</h1>
        <p class="t-20">
          best-troffers.com adresinden erişilebilen best-troffers.com'da,
          ziyaretçilerimizin gizliliği en önemli önceliklerimizden biridir. Bu
          Gizlilik Politikası belgesi, best-troffers.com tarafından toplanan ve
          kaydedilen bilgi türlerini ve bunları nasıl kullandığımızı
          içermektedir.
          <br />
          Gizlilik Politikamız hakkında ek sorularınız varsa veya daha fazla
          bilgiye ihtiyacınız varsa, lütfen bizimle iletişime geçmekten
          çekinmeyin.
        </p>
        <h2>Kayıt Dosyaları</h2>
        <p class="t-20">
          best-troffers.com, kayıt dosyaları kullanma konusunda standart bir
          prosedürü izler. Ziyaretçiler web sitelerini ziyaret ettiğinde bu
          dosyalar kaydedilir. Tüm barındırma şirketleri bunu yapar ve
          barındırma hizmetlerinin bir parçası olarak analizler yapar. Kayıt
          dosyaları tarafından toplanan bilgiler internet protokolü (IP)
          adresleri, tarayıcı türü, İnternet Servis Sağlayıcı (ISP), tarih ve
          saat damgası, yönlendirme/çıkış sayfaları ve tıklama sayısını
          içerebilir. Bu bilgiler, kişisel olarak tanımlanabilir bilgilere
          bağlanmaz. Bilgilerin amacı, eğilimleri analiz etmek, siteyi yönetmek,
          kullanıcıların web sitesindeki hareketlerini izlemek ve demografik
          bilgileri toplamaktır. Gizlilik Politikamız, Gizlilik Politikası
          Oluşturucu yardımıyla oluşturulmuştur.
        </p>
        <h2>Reklam Ortaklarımız</h2>
        <p class="t-20">
          Sitemizdeki bazı reklamverenler çerezler ve web işaretçileri
          kullanabilir. Reklam ortaklarımız aşağıda listelenmiştir. Her bir
          reklam ortağımızın kullanıcı verileriyle ilgili kendi Gizlilik
          Politikası vardır. Daha kolay erişim sağlamak için Gizlilik
          Politikalarını aşağıda hiperlinklerle sunduk.
        </p>
        <h2>Gizlilik Politikaları</h2>
        <p class="t-20">
          best-troffers.com'un reklam ortaklarının Gizlilik Politikalarını
          bulmak için bu listeye başvurabilirsiniz.
          <br />
          Üçüncü taraf reklam sunucuları veya reklam ağları,
          best-troffers.com'da görünen reklamlar ve bağlantılar için çerezler,
          JavaScript veya Web İşaretçileri gibi teknolojiler kullanır ve bunlar
          doğrudan kullanıcıların tarayıcılarına gönderilir. Bu gerçekleştiğinde
          otomatik olarak IP adresinizi alırlar. Bu teknolojiler, reklam
          kampanyalarının etkinliğini ölçmek ve/veya ziyaret ettiğiniz web
          sitelerinde gördüğünüz reklam içeriğini kişiselleştirmek için
          kullanılır. <br />
          best-troffers.com'un bu üçüncü taraf reklamverenler tarafından
          kullanılan çerezlere erişimi veya kontrolü olmadığını unutmayın.
        </p>
        <h2>Üçüncü Taraf Gizlilik Politikaları</h2>
        <p class="t-20">
          best-troffers.com'un Gizlilik Politikası, diğer reklamverenler veya
          web siteleri için geçerli değildir. Bu nedenle, daha ayrıntılı bilgi
          için bu üçüncü taraf reklam sunucularının ilgili Gizlilik
          Politikalarına başvurmanızı öneririz. Bu politikalar, belirli
          seçeneklerden nasıl vazgeçileceğine dair uygulamaları ve talimatları
          içerebilir.
          <br />
          Çerezleri devre dışı bırakmayı tarayıcınızın bireysel ayarları
          aracılığıyla tercih edebilirsiniz. Belirli web tarayıcılarıyla çerez
          yönetimi hakkında daha ayrıntılı bilgi, tarayıcıların ilgili web
          sitelerinde bulunabilir.
        </p>
        <h2>Çocukların Bilgileri</h2>
        <p class="t-20">
          İnternet kullanımında çocukların korunmasını artırmak,
          önceliklerimizden biridir. Ebeveynleri ve velileri çocuklarının
          çevrimiçi faaliyetlerini gözlemlemeye, katılmaya ve/veya izlemeye ve
          yönlendirmeye teşvik ediyoruz.
          <br />
          best-troffers.com, 13 yaşın altındaki çocuklardan bilerek hiçbir
          Kişisel Tanımlanabilir Bilgi toplamaz. Çocuğunuzun web sitemizde bu
          tür bilgileri sağladığını düşünüyorsanız, lütfen hemen bizimle
          iletişime geçin ve bu bilgileri kayıtlarımızdan derhal kaldırmak için
          elimizden geleni yapacağız.
        </p>
        <h2>Yalnızca Çevrimiçi Gizlilik Politikası</h2>
        <p class="t-20">
          Bu Gizlilik Politikası yalnızca çevrimiçi faaliyetlerimiz için
          geçerlidir ve best-troffers.com'da ziyaretçilerin paylaştığı ve/veya
          topladığı bilgiler için geçerlidir. Bu politika, bu web sitesi
          dışındaki kanallar aracılığıyla toplanan bilgilere veya çevrimdışı
          olarak toplanan bilgilere uygulanmaz.
        </p>
        <h2>Onay</h2>
        <p class="t-20">
          Web sitemizi kullanarak Gizlilik Politikamızı kabul etmiş ve Şartlar
          ve Koşullarını kabul etmiş olursunuz.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
