import React from "react";
import "./Directions.css";
import { Link } from "react-router-dom";
import closeBtn from "../../images/close-btn.svg";
import turkey from "../../images/turkey-link.webp";
import denmark from "../../images/denmark-link.webp";
import holland from "../../images/holland-link.webp";

const Directions = ({ isVisible, close }) => {
  return (
    <>
      {isVisible && (
        <div className="trof-popup">
          <section className="trof-directions-popup">
            <img
              src={closeBtn}
              alt="close button"
              title="close button"
              className="trof-directions__close"
              onClick={() => {
                close();
              }}
            />
            <div className="trof-directions__text">
              <h2 className="trof-directions__header">yol tarifi seç</h2>
              <p className="trof-directions__paragraph t-24">
                Avrupa’ya unutulmaz bir seyahat mi planlıyorsunuz? Seçiminizde
                size yardımcı olmaya hazırız! Avrupa, her biri kendine has
                özelliklere sahip ve tarihi, kültürü ile hayranlık uyandıran
                birçok farklı destinasyon sunuyor. En iyi oteller, cazibe
                merkezleri ve bu harika şehirlerdeki gezilecek yerler hakkında
                bilgi edinin.
              </p>
            </div>

            <div className="trof-directions__link-container">
              <Link
                to="/turkey"
                className="trof-directions__link"
                onClick={() => {
                  close();
                }}
              >
                <img
                  src={turkey}
                  alt="link to page about turkey"
                  title="link to page about turkey"
                  width="333"
                  height="240"
                  className="trof-directions__img"
                />
                <div className="trof-directions__overlay">
                  <p className="t-24 col-yellow trof-directions__overlay-text">
                    Türkiye'deki Oteller
                  </p>
                </div>
              </Link>

              <Link
                to="/denmark"
                className="trof-directions__link"
                onClick={() => {
                  close();
                }}
              >
                <img
                  src={denmark}
                  alt="link to page about denmark"
                  title="link to page about denmark"
                  width="333"
                  height="240"
                  className="trof-directions__img"
                />
                <div className="trof-directions__overlay">
                  <p className="t-24 col-yellow trof-directions__overlay-text">
                    Danimarka'daki Oteller
                  </p>
                </div>
              </Link>
              <Link
                to="/holland"
                className="trof-directions__link grid-two"
                onClick={() => {
                  close();
                }}
              >
                <img
                  src={holland}
                  alt="link to page about holland"
                  title="link to page about holland"
                  width="333"
                  height="500"
                  className="trof-directions__img"
                />
                <div className="trof-directions__overlay">
                  <p className="t-24 col-yellow trof-directions__overlay-text">
                    Hollanda'daki Oteller
                  </p>
                </div>
              </Link>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Directions;
