import React from "react";
import "./DirectionPages.css";

import hotOne from "../../images/Hotel-Royal.webp";
import hotTwo from "../../images/Marienlyst-Strandhotel.webp";
import hotThree from "../../images/Munkebjerg-Hotel.webp";
import hotFour from "../../images/Radisson-Blu-Scandinavia-Hotel.webp";
import hotFive from "../../images/Helsingor-Camping.webp";
import hotSix from "../../images/Pension-LyngbjergGaard.webp";

import pin from "../../images/pin.svg";
import time from "../../images/time.svg";
import events from "../../images/events.svg";

const Denmark = () => {
  return (
    <main className="trof-direction-page">
      <section className="trof-cover trof-direction-page-cover page-two">
        <div className="trof-cover__overlay">
          <h1 className="trof-cover__header">Danimarka</h1>
        </div>
      </section>

      <section className="trof-hotels trof-direction__pages pad">
        <h2 className="trof-hotels__header text-center">
          En İyi Otel Tavsiyelerimiz
        </h2>
        <p className="t-24  hotels__text">
          Danimarka, göz alıcı fiyortları, tarihi kaleleri ve modern İskandinav
          tasarımıyla ünlü bir ülkedir. Seyahat acentemiz, gerçek Danimarka
          misafirperverliğini ve rahatlığını yaşamanız için en iyi otelleri
          seçti. Başkent Kopenhag'daki modern butik otellerden, Jutland'ın doğal
          güzellikleri içindeki sakin tatil köylerine kadar unutulmaz bir tatil
          için mükemmel seçenekler sunuyoruz.
        </p>
        <ul className="trof-hotels__container">
          <li className="trof-hotel hotel-one">
            <div className="trof-hotel__img-container">
              <img
                src={hotOne}
                alt="Hotel Royal"
                title="Hotel Royal"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">Hotel Royal</p>
              <p className="trof-hotel__about t-20">
                Aarhus Un kalbinde yer alan Hotel Royal, klasik zarafeti modern
                olanaklarla birleştiren lüks bir sığınaktır. Zengin tarihi,
                süslü mimarisinde ve sofistike iç mekanlarında kendini gösterir
                ve konuklara içeri adım attıkları andan itibaren muhteşem bir
                deneyim sunar. En üst düzeyde konfor sağlamak için titizlikle
                tasarlanan odaların her biri peluş yatak takımları, ısmarlama
                mobilyalar ve son teknoloji olanaklarla donatılmıştır. Otelde
                ayrıca, konukların en iyi şefler tarafından hazırlanan Danimarka
                ve dünya mutfaklarının tadını çıkarabilecekleri seçkin bir
                restoran ve bar bulunmaktadır. Rahatlamak isteyenler için otelin
                sağlıklı yaşam merkezi, bedeni ve zihni gençleştiren çeşitli
                bakımlar sunmaktadır.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Store Torv 4, Aarhus 8000 Denmark
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Hotel Royal, şık düğünler, kurumsal toplantılar ve özel partiler
                de dahil olmak üzere, tümü konuklarının farklı ihtiyaçlarını
                karşılamak üzere tasarlanmış bir dizi etkinliğe ev sahipliği
                yapmaktadır. Kusursuz bir uygulama sağlayan özel personeliyle
                her etkinlik unutulmaz olmayı vaat ediyor.
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g189530-d206744-Reviews-Hotel_Royal-Aarhus_East_Jutland_Jutland.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-two">
            <div className="trof-hotel__img-container">
              <img
                src={hotTwo}
                alt="Marienlyst Strandhotel"
                title="Marienlyst Strandhotel"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Marienlyst Strandhotel
              </p>
              <p className="trof-hotel__about t-20">
                Helsingør'un doğal kıyılarında yer alan Marienlyst Strandhotel,
                kıyı cazibesi ile çağdaş lüksün mükemmel bir karışımını
                sunmaktadır. Konuklar, konaklamaları için sakin bir zemin
                sağlayan Øresund Boğazı'nın muhteşem manzarasıyla karşılanır.
                Otel, her biri şık bir dekora ve modern olanaklara sahip, zarif
                bir şekilde döşenmiş oda ve süitlere sahiptir. Yerel kaynaklı
                malzemelerin gurme başyapıtlara dönüştürüldüğü otelin
                restoranlarında mutfak lezzetleri sizi bekliyor. Konuklar,
                çeşitli şımartıcı uygulamalar ve huzurlu bir atmosfer sunan otel
                bünyesindeki spada gevşeyebilirler.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Nordre Strandvej 2A, Helsingør 3000 Denmark
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Marienlyst Strandhotel düğünler, konferanslar ve sosyal
                etkinlikler için ideal bir mekandır. Otelin etkinlik alanları en
                son teknolojiyle donatılmış olup nefes kesici manzaralar sunarak
                her etkinliğin unutulmaz olmasını sağlar.
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g189542-d232135-Reviews-Marienlyst_Strandhotel-Helsingoer_Helsingoer_Municipality_Copenhagen_Region_Zealand.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-three">
            <div className="trof-hotel__img-container">
              <img
                src={hotThree}
                alt="Munkebjerg Hotel"
                title="Munkebjerg Hotel"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">Munkebjerg Hotel</p>
              <p className="trof-hotel__about t-20">
                Vejle'nin yemyeşil ormanlarının ortasında yer alan Munkebjerg
                Hotel, bir huzur ve zarafet cennetidir. Vejle Fiyordu'nun
                panoramik manzarasını sunan bu büyüleyici otel, dinlenmek ve
                eğlenmek için cennet gibi bir ortam yaratmaktadır. Konuklar
                çağdaş tasarıma ve modern olanaklara sahip iyi döşenmiş odaların
                keyfini çıkarabilir. Otelin ünlü restoranı, Danimarka mutfağının
                en iyi örneklerini çağdaş bir dokunuşla öne çıkaran nefis
                yemekler sunmaktadır. Eğlence arayanlar için Munkebjerg Casino
                heyecan verici bir dizi oyun ve aktivite sunmaktadır.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Munkebjergvej 125, Vejle 7100 Denmark
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Munkebjerg Hotel, samimi toplantılardan büyük kutlamalara kadar
                çeşitli etkinliklere ev sahipliği yapma konusunda
                uzmanlaşmıştır. Çok yönlü etkinlik alanları ve uzman planlama
                hizmetleriyle, her etkinlik hassasiyet ve yetenekle
                gerçekleştirilir ve herkes için unutulmaz bir deneyim sağlanır.
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g226904-d482235-Reviews-Munkebjerg_Hotel-Vejle_South_Jutland_Jutland.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-four">
            <div className="trof-hotel__img-container">
              <img
                src={hotFour}
                alt="Radisson Blu Scandinavia Hotel, Copenhagen"
                title="Radisson Blu Scandinavia Hotel, Copenhagen"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Radisson Blu Scandinavia Hotel, Copenhagen
              </p>
              <p className="trof-hotel__about t-20">
                Kopenhag'daki Radisson Blu Scandinavia Hotel, konfor ve
                rahatlığın benzersiz bir karışımını sunan lüks bir oteldir.
                Hareketli şehir merkezine yakın bir konumda yer alan otel, şehir
                manzarasının ve büyüleyici Amager ve Øresund adalarının muhteşem
                manzaralarını sunmaktadır. Her biri modern olanaklara ve
                İskandinav tasarımına sahip 544 şık oda ve süit ile konukların
                konforlu ve unutulmaz bir konaklama geçirmesi sağlanır. Otel,
                otantik İtalyan mutfağıyla tanınan ünlü Filini Restaurant ve
                zarif Japon yemekleri sunan enfes Kyoto Restaurant gibi çeşitli
                yemek seçeneklerine sahiptir. Dinlenmek veya aktif kalmak
                isteyenler için otelde iyi donanımlı bir fitness merkezi ve
                kumarhane bulunmaktadır. Tesis, son teknolojiyle donatılmış 16
                toplantı odasıyla iş veya sosyal etkinlikler için de idealdir.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Amager Boulevard 70, 2300 Copenhagen S, Danimarka
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                Öğleden sonra 3'ten itibaren giriş, Öğleden sonra 12'ye kadar
                çıkış
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Otel çeşitli kurumsal etkinliklere, iş toplantılarına ve özel
                işlevlere ev sahipliği yapmaktadır. Ayrıca düğünler ve özel
                günler için özel paketler de sunmaktadır.
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g189541-d206740-Reviews-Radisson_Blu_Scandinavia_Hotel_Copenhagen-Copenhagen_Zealand.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-five">
            <div className="trof-hotel__img-container">
              <img
                src={hotFive}
                alt="Helsingor Camping"
                title="Helsingor Camping"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">Helsingor Camping</p>
              <p className="trof-hotel__about t-20">
                Helsingor Camping, doğal sahil kasabası Helsingør'da yer alan
                keyifli bir kamp yeridir. Bu büyüleyici kamp alanı, doğa
                severler ve huzurlu bir inziva arayanlar için mükemmeldir.
                Yemyeşil doğayla çevrili ve muhteşem plajlara sadece bir taş
                atımı uzaklıkta yer alan tesiste konuklar yürüyüş, bisiklet ve
                yüzme gibi çeşitli açık hava etkinliklerinin keyfini
                çıkarabilir. Kamp alanında çadırlar, karavanlar ve motorlu
                karavanlar için geniş sahaların yanı sıra rahat kabinler de
                dahil olmak üzere çeşitli konaklama seçenekleri sunulmaktadır.
                Tesisler arasında modern bir sıhhi bina, çocuklar için bir oyun
                alanı ve bir market bulunmaktadır. Kronborg Kalesi gibi kültürel
                simge yapılara yakınlığı deneyimi zenginleştirmekte ve burayı
                hem dinlenmek hem de keşfetmek için ideal bir nokta haline
                getirmektedir.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Strandalleen 2, 3000 Helsingør, Danimarka
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                Nisan'dan Ekim'e kadar açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Helsingor Camping, barbekü geceleri ve yerel cazibe merkezlerine
                rehberli turlar gibi mevsimlik faaliyet ve etkinliklere ev
                sahipliği yapmaktadır.
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g189542-d1841701-Reviews-Helsingor_Camping-Helsingoer_Helsingoer_Municipality_Copenhagen_Region_Zealand.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-six">
            <div className="trof-hotel__img-container">
              <img
                src={hotSix}
                alt="Pension LyngbjergGaard"
                title="Pension LyngbjergGaard"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Pension LyngbjergGaard
              </p>
              <p className="trof-hotel__about t-20">
                Kuzey Jutland'ın sakin kırsalında yer alan Pension
                LyngbjergGaard, evden uzaktaki bir ev gibi hissettiren sıcak ve
                samimi bir atmosfer sunmaktadır. Pitoresk manzaraların ortasında
                yer alan bu büyüleyici konukevi, günlük hayatın
                koşuşturmacasından sakin bir kaçış sağlamaktadır. Konuklar,
                geleneksel Danimarka tarzında dekore edilmiş ve her biri
                dinlendirici bir konaklama için gerekli olanaklarla donatılmış
                konforlu odalar arasından seçim yapabilirler. Konukevinin güzel
                bahçesi, keyifli yürüyüşler yapmak veya bir kitapla sakin bir
                anın tadını çıkarmak için idealdir. Her sabah yerel lezzetleri
                ve malzemeleri öne çıkaran lezzetli bir ev yapımı kahvaltı
                servis edilmektedir. Jammerbugt plajları ve Fjerritslev kasabası
                da dahil olmak üzere yakındaki cazibe merkezlerini keşfetmek
                için mükemmel bir yerdir.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Lyngbjergvej 2, 9690 Fjerritslev, Danimarka
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                Öğleden sonra 3'ten itibaren giriş, Sabah 11'e kadar çıkış
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Pension LyngbjergGaard zaman zaman yerel el sanatları ve mutfak
                deneyimlerine odaklanan küçük toplantılar ve atölye çalışmaları
                düzenlemektedir.
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g656486-d3209257-Reviews-Pension_LyngbjergGaard-Fjerritslev_Jammerbugt_Municipality_North_Jutland_Jutland.html"
              >
                rezerv
              </a>
            </div>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default Denmark;
