import React from "react";
import "./HotDeals.css";

import hotOne from "../../images/Avrasya-Hostel.webp";
import hotTwo from "../../images/Marienlyst-Strandhotel.webp";
import hotThree from "../../images/WestCord-WTC-Hotel-Leeuwarden.webp";
import hotFour from "../../images/Radisson-Blu-Scandinavia-Hotel.webp";
import hotFive from "../../images/Van-der-Valk-Hotel-Tiel.webp";

import pin from "../../images/pin.svg";
import time from "../../images/time.svg";
import events from "../../images/events.svg";
import { useLocation } from "react-router-dom";

const HotDeals = () => {
  const location = useLocation();

  return (
    <section className="trof-hotels pad">
      {location.pathname === "/hot-deals" ? (
        <h1 className="trof-hotels__header">sıcak teklifler</h1>
      ) : (
        <h2 className="trof-hotels__header">sıcak teklifler</h2>
      )}
      <ul className="trof-hotels__container">
        <li className="trof-hotel hotel-one">
          <div className="trof-hotel__img-container">
            <img
              src={hotOne}
              alt="Avrasya Hostel"
              title="Avrasya Hostel"
              width="417"
              height="490"
              className="trof-hotel__img"
            />
          </div>
          <div className="trof-hotel__text-container">
            <p className="t-32 hotel__name col-yellow">Avrasya Hostel</p>
            <p className="trof-hotel__about t-20">
              Avrasya Hostel, şehrin kalbinde rahat ve bütçe dostu bir konaklama
              imkanı sunmaktadır. Konuksever atmosferiyle tanınan bu hostel,
              yerel kültürü uygun bir bütçeyle keşfetmek isteyen gezginler için
              idealdir. Konfor düşünülerek tasarlanmış odalarda keyifli bir
              konaklama sağlamak için temel olanaklar mevcuttur. Konuklar, uzun
              süreli konaklamalar veya kendi yemeklerini pişirmeyi tercih
              edenler için uygun hale getiren ortak mutfağın keyfini
              çıkarabilirler. Güler yüzlü personel, şehir deneyiminizi
              zenginleştirecek seyahat ipuçları ve tavsiyeler konusunda her
              zaman yardımcı olmaya hazırdır.
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={pin}
                alt="pin icon"
                title="pin icon"
                width="25"
                height="25"
              />
              Cankurtaran Mahallesi Seyit Hasan Sokak No:12 Sultanahmet,
              İstanbul 34110 Türkiye
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={time}
                alt="time icon"
                title="time icon"
                width="25"
                height="25"
              />
              24 saat açık
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={events}
                alt="events icon"
                title="events icon"
                width="25"
                height="25"
              />
              Şehir turları ve günlük geziler
            </p>
            <a
              className="trof-hotel__reserve t-20"
              target="_blank"
              rel="noreferrer"
              href="https://www.tripadvisor.com/Hotel_Review-g293974-d6429248-Reviews-Avrasya_Hostel-Istanbul.html"
            >
              rezerv
            </a>
          </div>
        </li>

        <li className="trof-hotel hotel-two">
          <div className="trof-hotel__img-container">
            <img
              src={hotTwo}
              alt="Marienlyst Strandhotel"
              title="Marienlyst Strandhotel"
              width="417"
              height="490"
              className="trof-hotel__img"
            />
          </div>
          <div className="trof-hotel__text-container">
            <p className="t-32 hotel__name col-yellow">
              Marienlyst Strandhotel
            </p>
            <p className="trof-hotel__about t-20">
              Helsingør'un doğal kıyılarında yer alan Marienlyst Strandhotel,
              kıyı cazibesi ile çağdaş lüksün mükemmel bir karışımını
              sunmaktadır. Konuklar, konaklamaları için sakin bir zemin sağlayan
              Øresund Boğazı'nın muhteşem manzarasıyla karşılanır. Otel, her
              biri şık bir dekora ve modern olanaklara sahip, zarif bir şekilde
              döşenmiş oda ve süitlere sahiptir. Yerel kaynaklı malzemelerin
              gurme başyapıtlara dönüştürüldüğü otelin restoranlarında mutfak
              lezzetleri sizi bekliyor. Konuklar, çeşitli şımartıcı uygulamalar
              ve huzurlu bir atmosfer sunan otel bünyesindeki spada
              gevşeyebilirler.
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={pin}
                alt="pin icon"
                title="pin icon"
                width="25"
                height="25"
              />
              Nordre Strandvej 2A, Helsingør 3000 Denmark
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={time}
                alt="time icon"
                title="time icon"
                width="25"
                height="25"
              />
              24 saat açık
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={events}
                alt="events icon"
                title="events icon"
                width="25"
                height="25"
              />
              Marienlyst Strandhotel düğünler, konferanslar ve sosyal
              etkinlikler için ideal bir mekandır. Otelin etkinlik alanları en
              son teknolojiyle donatılmış olup nefes kesici manzaralar sunarak
              her etkinliğin unutulmaz olmasını sağlar.
            </p>
            <a
              className="trof-hotel__reserve t-20"
              target="_blank"
              rel="noreferrer"
              href="https://www.tripadvisor.com/Hotel_Review-g189542-d232135-Reviews-Marienlyst_Strandhotel-Helsingoer_Helsingoer_Municipality_Copenhagen_Region_Zealand.html"
            >
              rezerv
            </a>
          </div>
        </li>
        <li className="trof-hotel hotel-three">
          <div className="trof-hotel__img-container">
            <img
              src={hotThree}
              alt="WestCord WTC Hotel Leeuwarden"
              title="WestCord WTC Hotel Leeuwarden"
              width="417"
              height="490"
              className="trof-hotel__img"
            />
          </div>
          <div className="trof-hotel__text-container">
            <p className="t-32 hotel__name col-yellow">
              WestCord WTC Hotel Leeuwarden
            </p>
            <p className="trof-hotel__about t-20">
              WestCord WTC Hotel Leeuwarden, hareketli Leeuwarden kentinde
              sofistike bir dinlenme olanağı sunmaktadır. WTC Expo ile doğrudan
              bağlantılı olan otel, iş amaçlı seyahat edenler için mükemmeldir.
              Modern odaları, panoramik manzaralı şık restoranı ve sağlıklı
              yaşam alanıyla tüm ihtiyaçlarınızı karşılar. Otelin birinci sınıf
              konumu sayesinde Leeuwarden'in zengin tarihini ve kültürünü
              kolaylıkla keşfedin. İster iş ister eğlence için ziyaret edin,
              rahatlığı konforla birleştiren bir konaklama bekleyin.
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={pin}
                alt="pin icon"
                title="pin icon"
                width="25"
                height="25"
              />
              Heliconweg 52, 8914 AT Leeuwarden The Netherlands
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={time}
                alt="time icon"
                title="time icon"
                width="25"
                height="25"
              />
              24 saat açık
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={events}
                alt="events icon"
                title="events icon"
                width="25"
                height="25"
              />
              Ticari fuarlar ve konferanslar
            </p>
            <a
              className="trof-hotel__reserve t-20"
              target="_blank"
              rel="noreferrer"
              href="https://www.tripadvisor.com/Hotel_Review-g188563-d1414985-Reviews-WestCord_WTC_Hotel_Leeuwarden-Leeuwarden_Friesland_Province.html"
            >
              rezerv
            </a>
          </div>
        </li>

        <li className="trof-hotel hotel-four">
          <div className="trof-hotel__img-container">
            <img
              src={hotFour}
              alt="Radisson Blu Scandinavia Hotel, Copenhagen"
              title="Radisson Blu Scandinavia Hotel, Copenhagen"
              width="417"
              height="490"
              className="trof-hotel__img"
            />
          </div>
          <div className="trof-hotel__text-container">
            <p className="t-32 hotel__name col-yellow">
              Radisson Blu Scandinavia Hotel, Copenhagen
            </p>
            <p className="trof-hotel__about t-20">
              Kopenhag'daki Radisson Blu Scandinavia Hotel, konfor ve rahatlığın
              benzersiz bir karışımını sunan lüks bir oteldir. Hareketli şehir
              merkezine yakın bir konumda yer alan otel, şehir manzarasının ve
              büyüleyici Amager ve Øresund adalarının muhteşem manzaralarını
              sunmaktadır. Her biri modern olanaklara ve İskandinav tasarımına
              sahip 544 şık oda ve süit ile konukların konforlu ve unutulmaz bir
              konaklama geçirmesi sağlanır. Otel, otantik İtalyan mutfağıyla
              tanınan ünlü Filini Restaurant ve zarif Japon yemekleri sunan
              enfes Kyoto Restaurant gibi çeşitli yemek seçeneklerine sahiptir.
              Dinlenmek veya aktif kalmak isteyenler için otelde iyi donanımlı
              bir fitness merkezi ve kumarhane bulunmaktadır. Tesis, son
              teknolojiyle donatılmış 16 toplantı odasıyla iş veya sosyal
              etkinlikler için de idealdir.
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={pin}
                alt="pin icon"
                title="pin icon"
                width="25"
                height="25"
              />
              Amager Boulevard 70, 2300 Copenhagen S, Danimarka
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={time}
                alt="time icon"
                title="time icon"
                width="25"
                height="25"
              />
              Öğleden sonra 3'ten itibaren giriş, Öğleden sonra 12'ye kadar
              çıkış
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={events}
                alt="events icon"
                title="events icon"
                width="25"
                height="25"
              />
              Otel çeşitli kurumsal etkinliklere, iş toplantılarına ve özel
              işlevlere ev sahipliği yapmaktadır. Ayrıca düğünler ve özel günler
              için özel paketler de sunmaktadır.
            </p>
            <a
              className="trof-hotel__reserve t-20"
              target="_blank"
              rel="noreferrer"
              href="https://www.tripadvisor.com/Hotel_Review-g189541-d206740-Reviews-Radisson_Blu_Scandinavia_Hotel_Copenhagen-Copenhagen_Zealand.html"
            >
              rezerv
            </a>
          </div>
        </li>

        <li className="trof-hotel hotel-five">
          <div className="trof-hotel__img-container">
            <img
              src={hotFive}
              alt="Van der Valk Hotel Tiel"
              title="Van der Valk Hotel Tiel"
              width="417"
              height="490"
              className="trof-hotel__img"
            />
          </div>
          <div className="trof-hotel__text-container">
            <p className="t-32 hotel__name col-yellow">
              Van der Valk Hotel Tiel
            </p>
            <p className="trof-hotel__about t-20">
              Van der Valk Hotel Tiel sizi Betuwe bölgesinin kalbinde eşsiz
              konukseverliği deneyimlemeye davet ediyor. Canlı atmosferi ve
              mükemmel hizmetleriyle tanınan bu otelde şık oda ve süitler,
              sağlıklı yaşam merkezi ve nefes kesici manzaralara sahip bir çatı
              barı bulunmaktadır. Restoranlarında yemek deneyimlerinin tadını
              çıkarabilir veya iyi donanımlı toplantı alanlarında işlerinizi
              kolaylıkla yürütebilirsiniz. İdeal konumu sayesinde konuklar
              Flipje Müzesi ve nehir kenarındaki doğal manzaralar gibi yerel
              cazibe merkezlerini kolayca keşfedebilir.
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={pin}
                alt="pin icon"
                title="pin icon"
                width="25"
                height="25"
              />
              Laan van Westroijen 10, 4003 AZ, Tiel The Netherlands
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={time}
                alt="time icon"
                title="time icon"
                width="25"
                height="25"
              />
              24 saat açık
            </p>
            <p className="t-16 trof-hotel__small-container">
              <img
                src={events}
                alt="events icon"
                title="events icon"
                width="25"
                height="25"
              />
              Meyve turları ve gastronomi etkinlikleri
            </p>
            <a
              className="trof-hotel__reserve t-20"
              target="_blank"
              rel="noreferrer"
              href="https://www.tripadvisor.com/Hotel_Review-g941692-d603620-Reviews-Van_der_Valk_Hotel_Tiel-Tiel_Gelderland_Province.html"
            >
              rezerv
            </a>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default HotDeals;
