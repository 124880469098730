import React from "react";
import "./DirectionPages.css";

import hotOne from "../../images/Van-der-Valk.webp";
import hotTwo from "../../images/Van-Der-Valk-Hotel-Hoorn.webp";
import hotThree from "../../images/WestCord-WTC-Hotel-Leeuwarden.webp";
import hotFour from "../../images/Van-Der-Valk-Hotel-Sassenheim-Leiden.webp";
import hotFive from "../../images/Van-der-Valk-Hotel-Tiel.webp";
import hotSix from "../../images/Park-Inn-by-Radisson.webp";

import pin from "../../images/pin.svg";
import time from "../../images/time.svg";
import events from "../../images/events.svg";

const Holandia = () => {
  return (
    <main className="trof-direction-page">
      <section className="trof-cover trof-direction-page-cover page-three">
        <div className="trof-cover__overlay">
          <h1 className="trof-cover__header">Hollanda</h1>
        </div>
      </section>

      <section className="trof-hotels direction__pages pad">
        <h2 className="trof-hotels__header">En İyi Otel Tavsiyelerimiz</h2>
        <p className="t-24  hotels__text">
          Hollanda, resmedilmeye değer kanalları, çiçek tarlaları ve zengin
          kültürel mirası ile tanınan bir ülkedir. Seyahat acentemiz, gerçek
          Hollanda misafirperverliğinin ve konforunun keyfini çıkarabilmeniz
          için en iyi otelleri seçti. Amsterdam'daki şık şehir otellerinden
          kırsal bölgelerdeki sıcak, rahat evlere kadar, unutulmaz bir tatil
          için ideal bir yerimiz var
        </p>
        <ul className="trof-hotels__container">
          <li className="trof-hotel hotel-one">
            <div className="trof-hotel__img-container">
              <img
                src={hotOne}
                alt="Van der Valk Hotel Gilze-Tilburg"
                title="Van der Valk Hotel Gilze-Tilburg"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Van der Valk Hotel Gilze-Tilburg
              </p>
              <p className="trof-hotel__about t-20">
                Van der Valk Hotel Gilze-Tilburg'da eğlence ve konforun en
                iyisini yaşayın. Hollanda'nın kalbinde yer alan bu otel, lüks
                olanaklarla huzurlu bir kaçış sunuyor. Otel bünyesindeki
                restoranda kaliteli yemeklerin tadını çıkarın, sağlıklı yaşam
                merkezinde gevşeyin veya Efteling tema parkı ve Tilburg şehir
                merkezi gibi yakındaki cazibe merkezlerini keşfedin. Hem iş hem
                de tatil amaçlı seyahat edenler için mükemmel olan bu otel,
                modern konferans olanakları ve rahat konaklama birimleri ile
                unutulmaz bir konaklama vadediyor.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Klein Zwitserland 8, 5126 TA Gilze The Netherlands
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Bowling ve restoran etkinlikleri
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g666950-d576339-Reviews-Van_der_Valk_Hotel_Gilze_Tilburg-Gilze_North_Brabant_Province.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-two">
            <div className="trof-hotel__img-container">
              <img
                src={hotTwo}
                alt="Van Der Valk Hotel Hoorn"
                title="Van Der Valk Hotel Hoorn"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Van Der Valk Hotel Hoorn
              </p>
              <p className="trof-hotel__about t-20">
                Van Der Valk Hotel Hoorn'da modernlik ve geleneğin bir
                karışımını keşfedin. Pitoresk Hoorn kasabasında yer alan bu
                otel, muhteşem manzaralara sahip çağdaş odalar, son teknoloji
                ürünü bir kumarhane ve kapsamlı toplantı olanakları sunmaktadır.
                Otelin restoranlarında gurme yemeklerin tadını çıkarın veya
                sağlıklı yaşam merkezinde rahatlayın. İster iş, ister romantik
                bir kaçamak ya da aile gezisi için burada olun, otelin kültürel
                cazibe merkezlerine ve alışveriş bölgelerine yakın stratejik
                konumu onu ideal bir seçim haline getirmektedir.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Westfriese Parkweg 1, 1625 MA, Hoorn The Netherlands
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Şarap tadımları ve bisiklet turları
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g188595-d6550932-Reviews-Van_Der_Valk_Hotel_Hoorn-Hoorn_North_Holland_Province.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-three">
            <div className="trof-hotel__img-container">
              <img
                src={hotThree}
                alt="WestCord WTC Hotel Leeuwarden"
                title="WestCord WTC Hotel Leeuwarden"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                WestCord WTC Hotel Leeuwarden
              </p>
              <p className="trof-hotel__about t-20">
                WestCord WTC Hotel Leeuwarden, hareketli Leeuwarden kentinde
                sofistike bir dinlenme olanağı sunmaktadır. WTC Expo ile
                doğrudan bağlantılı olan otel, iş amaçlı seyahat edenler için
                mükemmeldir. Modern odaları, panoramik manzaralı şık restoranı
                ve sağlıklı yaşam alanıyla tüm ihtiyaçlarınızı karşılar. Otelin
                birinci sınıf konumu sayesinde Leeuwarden'in zengin tarihini ve
                kültürünü kolaylıkla keşfedin. İster iş ister eğlence için
                ziyaret edin, rahatlığı konforla birleştiren bir konaklama
                bekleyin.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Heliconweg 52, 8914 AT Leeuwarden The Netherlands
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Ticari fuarlar ve konferanslar
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g188563-d1414985-Reviews-WestCord_WTC_Hotel_Leeuwarden-Leeuwarden_Friesland_Province.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-four">
            <div className="trof-hotel__img-container">
              <img
                src={hotFour}
                alt="Van Der Valk Hotel Sassenheim-Leiden"
                title="Van Der Valk Hotel Sassenheim-Leiden"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Van Der Valk Hotel Sassenheim-Leiden
              </p>
              <p className="trof-hotel__about t-20">
                Büyüleyici Güney Hollanda bölgesinde yer alan Van Der Valk Hotel
                Sassenheim-Leiden, lüks ve rahatlamanın en iyi karışımını sunar.
                Hareketli Leiden'e ve güzel Hollanda kıyılarına kısa bir sürüş
                mesafesindeki bu otel, geniş odalar, birinci sınıf bir fitness
                merkezi ve çeşitli yemek seçenekleri sunmaktadır. Otelin modern
                konferans tesislerinde başarılı toplantılar düzenleyebilir veya
                yakınlardaki bisiklet ve golf gibi dinlence aktivitelerinin
                keyfini çıkarabilirsiniz. Bölgenin tarihi ve kültürel
                zenginliklerini keşfetmek için mükemmel bir üstür.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Warmonderweg 8, 2171 AH Sassenheim The Netherlands
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık çıkış
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Canlı yemek pişirme ve brunchlar
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g1087399-d2109140-Reviews-Van_Der_Valk_Hotel_Sassenheim_Leiden-Sassenheim_South_Holland_Province.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-five">
            <div className="trof-hotel__img-container">
              <img
                src={hotFive}
                alt="Van der Valk Hotel Tiel"
                title="Van der Valk Hotel Tiel"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Van der Valk Hotel Tiel
              </p>
              <p className="trof-hotel__about t-20">
                Van der Valk Hotel Tiel sizi Betuwe bölgesinin kalbinde eşsiz
                konukseverliği deneyimlemeye davet ediyor. Canlı atmosferi ve
                mükemmel hizmetleriyle tanınan bu otelde şık oda ve süitler,
                sağlıklı yaşam merkezi ve nefes kesici manzaralara sahip bir
                çatı barı bulunmaktadır. Restoranlarında yemek deneyimlerinin
                tadını çıkarabilir veya iyi donanımlı toplantı alanlarında
                işlerinizi kolaylıkla yürütebilirsiniz. İdeal konumu sayesinde
                konuklar Flipje Müzesi ve nehir kenarındaki doğal manzaralar
                gibi yerel cazibe merkezlerini kolayca keşfedebilir.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                Laan van Westroijen 10, 4003 AZ, Tiel The Netherlands
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Meyve turları ve gastronomi etkinlikleri
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g941692-d603620-Reviews-Van_der_Valk_Hotel_Tiel-Tiel_Gelderland_Province.html"
              >
                rezerv
              </a>
            </div>
          </li>

          <li className="trof-hotel hotel-six">
            <div className="trof-hotel__img-container">
              <img
                src={hotSix}
                alt="Park Inn by Radisson Amsterdam City West"
                title="Park Inn by Radisson Amsterdam City West"
                width="417"
                height="490"
                className="trof-hotel__img"
              />
            </div>
            <div className="trof-hotel__text-container">
              <p className="t-32 hotel__name col-yellow">
                Park Inn by Radisson Amsterdam City West
              </p>
              <p className="trof-hotel__about t-20">
                Park Inn by Radisson Amsterdam City West'te şık bir şekilde
                konaklayın. Bu otel, Karim Rashid tarafından tasarlanan parlak,
                renkli dekora sahip çağdaş konaklama birimleri sunmaktadır.
                Günün 24 saati açık spor salonu, şık bir restoran ve mükemmel
                konferans alanları gibi son teknoloji tesislerin keyfini
                çıkarın. Amsterdam'ı keşfetmek için mükemmel bir konuma sahip
                olan otel, şehir merkezine ve Schiphol Havalimanı'na trenle kısa
                bir mesafededir. İster iş ister tatil amaçlı ziyaret edin, bu
                otel şehirde unutulmaz bir konaklama için dinamik bir üs sağlar.
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={pin}
                  alt="pin icon"
                  title="pin icon"
                  width="25"
                  height="25"
                />
                La Guardiaweg 59, 1043 DE Amsterdam The Netherlands
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={time}
                  alt="time icon"
                  title="time icon"
                  width="25"
                  height="25"
                />
                24 saat açık
              </p>
              <p className="t-16 trof-hotel__small-container">
                <img
                  src={events}
                  alt="events icon"
                  title="events icon"
                  width="25"
                  height="25"
                />
                Sanat sergileri ve şehir turları
              </p>
              <a
                className="trof-hotel__reserve t-20"
                target="_blank"
                rel="noreferrer"
                href="https://www.tripadvisor.com/Hotel_Review-g188590-d12386100-Reviews-Park_Inn_by_Radisson_Amsterdam_City_West-Amsterdam_North_Holland_Province.html"
              >
                rezerv
              </a>
            </div>
          </li>
        </ul>
      </section>
    </main>
  );
};

export default Holandia;
