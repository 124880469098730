import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="trof-popup-cookie">
        <form className="trof-popup__form">
          <p className="trof-popup__header text-center">Çerez Politikası</p>
          <p className="trof-popup__text t-20 text-center">
            Web sitemizdeki deneyiminizi mümkün olduğunca rahat ve yasal hale
            getirmek için çerezleri kullanıyoruz. Bu teknolojiler size
            tercihlerinize göre uyarlanmış yeni ve daha iyi hizmetler sunmamıza
            yardımcı olur.
          </p>
          <div className="trof-popup__btn-container">
            <a href="/cookies" className="trof-popup__btn-cookie t-20">
              Daha fazla ayrıntı
            </a>
            <p
              className="trof-popup__btn-cookie trof-popup__btn-agree t-20"
              onClick={addPopupCookie}
            >
              Onaylamak
            </p>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
